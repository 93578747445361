import React, { useEffect } from 'react';
import './App.less';
import { Routes, Route, useNavigate } from 'react-router-dom';
import LayoutComponent from './modules/container';
import Login from './modules/login';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setUser } from './modules/login/redux/actions';
// import ForgotPassword from './modules/forgotPassword';
import ResetPassword from './modules/forgotPassword/ResetPassword';
import UploadAttachments from './modules/UploadAttachments';
import TutorInvite from './modules/InviteTutor';
import ThankYou from './modules/thankYou';
import Sorry from './modules/inviteRejected';
import ResetPasswordSuccess from './modules/forgotPassword/resetPasswordSuccess';
import TermsAndConditions from './modules/termsAndConditions';

const App = () => {
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const localStorageUser = JSON.parse(localStorage.getItem('user'));
    if (localStorageUser) {
      axios.defaults.headers.common['Authorization'] =
        localStorageUser.auth_token;
      axios.defaults.headers.common['tenant'] = localStorageUser.tenant;

      dispatch(setUser(localStorageUser));
    } //  else navigate("/");
  }, []);

  return (
    <>
      <Routes>
        <Route
          path='/upload-attachments/:leadId'
          element={<UploadAttachments />}
        />
        <Route path='/invite/:invitation_id' element={<TutorInvite />} />
        <Route path='/invite-accepted' element={<ThankYou />} />
        <Route path='/invite-rejected' element={<Sorry />} />
        <Route path='/terms' element={<TermsAndConditions />} />
        <Route
          path='/reset-password-success'
          element={<ResetPasswordSuccess />}
        />
        <Route path='/reset-password/:token' element={<ResetPassword />} />

        {user === null && (
          <>
            {/* <Route path='/forgot-password' element={<ForgotPassword />} /> */}

            <Route path='*' element={<Login />} />
          </>
        )}
        {user !== null && <Route path='*' element={<LayoutComponent />} />}
      </Routes>
    </>
  );
};

export default App;
