import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Space,
  Col,
  Row,
  message,
  InputNumber,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/cross-icon.svg';
import sampleImg from '../../../assets/user-icon.svg';

import SpinnerComponent from '../../../components/spinner';
import { arrayMoveImmutable } from 'array-move';
import DragDropIcon from '../../../assets/dragdropicon.png';
import {
  createSiteStatuses,
  deleteSiteStatuses,
  fetchSiteStatuses,
  fetchSiteStatusesSuccess,
  isAddingAction,
  updateSiteOrder,
  updateSiteStatuses,
} from './redux/actions';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { baseURL } from '../../../globalUtils/axiosConfig';

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <img alt='' src={DragDropIcon} />);

const SiteTable = ({ canUpdate, canDelete, id }) => {
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState([]);
  const [icon, setIcon] = useState(null);
  const [isIconSelected, setIsIconSelected] = useState(false);
  const [allComments, setAllComments] = useState([]);

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const ref = useRef();
  const isEditing = (record) => record._id === editingKey;

  const { allStickers, isAdding } = useSelector(
    (state) => state.learningActivityStickersReducer
  );

  const { currentNpoObj } = useSelector(
    (state) => state.learningActivitiesReducer
  );

  useEffect(() => {
    if (currentNpoObj?._id) {
      dispatch(fetchSiteStatuses(currentNpoObj._id));
    }
    // let stickers = currentNpoObj?.stickers || [];
    // // setData(stickers);

    const comments = currentNpoObj?.comments || [];

    let options = comments.map((el) => ({
      label: el.name,
      value: el._id,
    }));
    setAllComments(options);

    // console.log('currentNpoObj', currentNpoObj);
  }, [currentNpoObj]);

  // console.log('data', data);

  // useEffect(() => {
  //   dispatch(fetchSiteStatuses(id));

  //   return () => {
  //     dispatch(isAddingAction(false));
  //   };
  // }, []);

  // useEffect(() => {
  //   setEditingLoading(updateLoading);
  //   if (!updateLoading) {
  //     setEditingKey('');
  //   }
  // }, [updateLoading]);

  useEffect(() => {
    setData(allStickers);
  }, [allStickers]);

  // const changeHandler = (event) => {
  //   const image = event.target.files[0];
  //   if (!image) {
  //     return false;
  //   }
  //   if (!image.name.match(/\.(svg)$/)) {
  //     message.error('Photo should be svg format.');
  //     return false;
  //   }
  //   if (image.size > 5120000) {
  //     message.error('Photo size should be less than 5MB.');
  //     return false;
  //   }
  //   setIcon(event.target.files[0]);
  //   setIsIconSelected(true);
  // };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    // const textInput = (
    //   <Input
    //     placeholder='Insert Sticker Name'
    //     ref={ref}
    //     onKeyPress={(e) => {
    //       if (e.key === 'Enter') {
    //         save(record._id, id);
    //       }
    //     }}
    //   />
    // );

    const numberInput = (
      <InputNumber
        placeholder='Insert Range'
        ref={ref}
        min={-10}
        max={10}
        width={120}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            save(record._id, id);
          }
        }}
      />
    );

    const commentsInput = (
      <Select
        mode='multiple'
        size='middle'
        style={{
          width: '100%',
        }}
        // disabled={!isEditing}
        placeholder={`${!isEditing ? '' : 'Select Comments'}`}
        // value={commentsSelected}
        // onChange={(e) => setCommentsSelected(e)}
        options={allComments}
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    );

    let inputNode = null;

    if (dataIndex === 'comments') {
      inputNode = commentsInput;
    }

    // if (dataIndex === 'icon') {
    //   inputNode = (
    //     <div style={{ width: '40px' }}>
    //       <div
    //         style={{
    //           width: '30px',
    //           height: '30px',
    //           textAlign: 'center',
    //         }}
    //       >
    //         <img
    //           width='100%'
    //           height='100%'
    //           style={{
    //             objectFit: 'contain',
    //           }}
    //           src={
    //             isIconSelected
    //               ? URL.createObjectURL(icon)
    //               : icon
    //               ? `${baseURL}/${icon}`
    //               : sampleImg
    //           }
    //           alt=''
    //         />
    //       </div>
    //       <div
    //         className='edit-icon-div'
    //         style={
    //           {
    //             // textAlign: 'center',
    //           }
    //         }
    //       >
    //         <input
    //           // readOnly={loading}
    //           // disabled={!isEdit}
    //           type='file'
    //           name='profileImg'
    //           id='profileImg'
    //           accept='image/*'
    //           style={{ display: 'none' }}
    //           onChange={changeHandler}
    //         />
    //         <label
    //           htmlFor='profileImg'
    //           style={{
    //             paddingLeft: '10px',
    //           }}
    //         >
    //           <EditIcon height={14} width={14} fill='#50B6F2' />
    //         </label>
    //       </div>
    //     </div>
    //   );
    // }

    if (['start_range', 'end_range'].includes(dataIndex)) {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
                width: '100%',
              }}
              rules={[
                {
                  required: true,
                  message: `Required!`,
                },
              ]}
            >
              {numberInput}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            valuePropName={'value'}
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Required!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    // setPrevCommentsSelected(record.comments.map((el) => el._id));
    dispatch(isAddingAction(true));
    const comments = record?.comments?.map((el) => el._id) || [];
    // if (record?.isAdd) {
    // } else {
    //   setIcon(record.icon || null);
    // }
    form.setFieldsValue({
      ...record,
      comments,
    });
    setEditingKey(record._id);
    setTimeout(() => {
      ref.current?.focus();
    }, 300);
    setIsIconSelected(false);
  };

  const cancel = (record) => {
    dispatch(isAddingAction(false));
    form.resetFields();
    setEditingKey('');
    if (record?.isAdd) {
      return setData((data) => data.filter((el) => el._id !== record._id));
    } else {
      setData(data);
    }
  };

  const onUpdate = () => {
    dispatch(isAddingAction(false));
    form.resetFields();
    setEditingKey('');
  };

  const save = async (id, learning_activity_id, record) => {
    // if (!icon) {
    //   return message.error('Sticker icon is required!');
    // }
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item._id);

      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          // if (isAlreadyExist({ ...item, ...row })) {
          //   return message.error('Sticker name already exist!');
          // }

          const { start_range, end_range, comments } = row;
          let obj = {
            _id: record._id,
            start_range,
            end_range,
            // id: editingKey,
            // sticker_id:
            comments: comments,
          };
          // if (isIconSelected) {
          //   obj.icon = icon;
          // }
          console.log('obj', obj);
          // return;
          dispatch(updateSiteStatuses({ ...obj, onUpdate }));
        }
      }
    } catch (errInfo) {
      if (errInfo?.values?.name === '') {
        message.error('Name should not be blank!');
      }
    }
  };

  // const handleDelete = (record) => {
  //   setEditingKey(record._id);
  //   dispatch(deleteSiteStatuses(record));
  // };

  // const handleAdd = () => {
  //   dispatch(isAddingAction(true));
  //   let _id = Math.floor(Math.random() * 1122123456);
  //   const newData = {
  //     _id,
  //     // icon: null,
  //     // name: '',
  //     start_range: null,
  //     end_range: null,
  //     isAdd: true,
  //   };
  //   setData((data) => [...data, newData]);
  //   setEditingKey(_id);
  //   setIcon(null);
  //   edit(newData);
  // };

  // const isAlreadyExist = (record) => {
  //   const { name } = record;
  //   const alreadyExist = data.filter(
  //     (el) => el.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase()
  //   );

  //   return alreadyExist.length > 0 && record._id !== alreadyExist[0]?._id
  //     ? true
  //     : false;
  // };

  const handleFinish = (values) => {
    // const { name } = values;
    // if (isAlreadyExist(values)) {
    //   return message.error('Status name already exist!');
    // }
    // dispatch(
    //   createSiteStatuses({
    //     name: name.trim(),
    //     id: editingKey,
    //     // icon,
    //     learning_activity_id: id,
    //   })
    // );
  };

  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      width: '10%',
      editable: false,
      align: 'left',
      render: (icon, record) => {
        if (!icon) {
          return;
        }

        return (
          <div
            style={{
              width: '30px',
              height: '30px',
              textAlign: 'center',
            }}
          >
            <img
              width='100%'
              height='100%'
              style={{
                objectFit: 'contain',
              }}
              src={`${baseURL}/${icon}`}
              alt='icon'
            />
          </div>
        );
      },
    },
    {
      title: 'Sticker Name',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      editable: false,
    },
    {
      title: 'Start Range',
      dataIndex: 'start_range',
      key: 'start_range',
      width: '15%',
      editable: true,
      required: true,
    },
    {
      title: 'End Range',
      dataIndex: 'end_range',
      key: 'end_range',
      width: '15%',
      editable: true,
      required: true,
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      width: '40%',
      editable: true,
      render: (comments, record) => {
        console.log('comments', comments);
        return comments?.map((el) => el.title).join(', ');
      },
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: '',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        // if (!canUpdate && !canDelete) {
        //   return;
        // }

        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record._id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <SaveIcon
                className='custom-icon'
                onClick={() => save(record._id, id, record)}
              />
              <Popconfirm
                title='Sure to cancel?'
                onConfirm={() => cancel(record)}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={20} align='center'>
            {canUpdate && (
              <EditIcon
                className={`custom-icon ${isAdding ? 'disable-icon' : ''}`}
                disabled={editingKey !== ''}
                onClick={() => !isAdding && edit(record)}
              />
            )}
            {/* {canDelete &&
              data.length >= 1 &&
              (isAdding ? (
                <DeleteIcon className={`custom-icon disable-icon`} />
              ) : (
                <Popconfirm
                  title='Are you sure you want to delete this status?'
                  onConfirm={() => handleDelete(record)}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteIcon className='custom-icon' />
                </Popconfirm>
              ))} */}
          </Space>
        );
      },
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width: '20%',
      className: 'drag-visible',
      align: 'center',
      render: (order, record) => {
        return !Boolean(editingKey) && canUpdate && <DragHandle />;
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        data.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      let updatedData = newData.map((el, index) => {
        return {
          ...el,
          position: index + 1,
        };
      });

      dispatch(
        updateSiteOrder({
          learning_stickers: updatedData,
          learning_activity_id: id,
        })
      );
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex(
      (x) => x.position == restProps['data-row-key']
    );

    return <SortableItem index={index} {...restProps} />;
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    // <Row justify='center' className='production-manager-parent-row'>
    <Row justify='center' className=''>
      {/* <Col xxl={24} xl={24} lg={24} md={24} className='heading-col'>
        <Row align='middle' gutter={[30, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Site Statuses</span>
            </div>
          </Col>
        </Row>
      </Col> */}

      <Col md={24} lg={23} xl={20} xxl={20} style={{ minHeight: '20vh' }}>
        <Form
          form={form}
          component={false}
          onFinish={handleFinish}
          className='table-style'
        >
          <Table
            className='special-table activity-type-table project-statuses-table'
            style={{
              marginTop: '0px',
            }}
            components={{
              body: {
                cell: EditableCell,
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            // scroll={{ x: !fetchingLoading }}
            rowClassName='editable-row'
            dataSource={data}
            // loading={fetchingLoading}
            columns={mergedColumns}
            pagination={false}
            rowKey='position'
          />
          {/* <br />
          <Col offset={1} lg={24} xl={18} xxl={17} md={24} className='table'>
            {canUpdate && (
              <Button
                icon={<PlusOutlined />}
                className='add-status-btn'
                size='middle'
                disabled={isAdding}
                onClick={handleAdd}
              >
                STICKER
              </Button>
            )}
          </Col> */}
        </Form>
      </Col>
    </Row>
  );
};

export default SiteTable;
