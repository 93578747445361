import React, { useEffect, useState } from "react";
import { Col, Input, Row, Form, Select, InputNumber, AutoComplete } from "antd";
import { ReactComponent as SaveIcon } from "../../../assets/save-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { createNpo, setNpoDetailsIsEdit, updateNpo } from "../redux/actions";
import SpinnerComponent from "../../../components/spinner";
// import { isValidNumber } from '../../../globalUtils/axiosConfig';
// import AttachmentsSection from './attachmentsSection';
import { useLocation } from "react-router-dom";
import { isValidNumber } from "../../../globalUtils/axiosConfig";
// import { fetchUserDetailsUtils } from '../../UserManager/redux/actions';

const Details = ({
  allStickersOptions,
  isNpoUpdate,
  canUpdate,
  prevSelected,
  allCommentsOptions,
  prevCommentsSelected,
  streamsOptions,
}) => {
  // const [documents, setDocuments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [utils, setUtils] = useState({});
  const [stickersSelected, setStickersSelected] = useState([]);
  const [commentsSelected, setCommentsSelected] = useState([]);
  const [currentNpo, setCurrentNpo] = useState({
    name: "",
    pdf_path: "",
    stream: "",
    final_assessment_required: true,
    peer_learning: true,
  });

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  // const { TextArea } = Input;
  const { Option } = Select;

  const { createNpoLoading, currentNpoObj, npoDetailsIsEdit } = useSelector(
    (state) => state.learningActivitiesReducer
  );

  // const { allNpoStatuses } = useSelector((state) => state.npoStatusReducer);
  // const { allNpoRegions } = useSelector((state) => state.npoRegionsReducer);

  // useEffect(() => {
  //   dispatch(fetchUserDetailsUtils());
  // }, []);

  useEffect(() => {
    if (currentNpoObj?._id) {
      setCurrentNpo(currentNpoObj);
      const {
        name,
        passing_percentage,
        stream = "",
        sequence = null,
        final_assessment_required = null,
        peer_learning = null,
      } = currentNpoObj;
      form.setFieldsValue({
        name,
        passing_percentage,
        stream,
        sequence,
        final_assessment_required,
        peer_learning,
      });
      setStickersSelected(prevSelected);
      setCommentsSelected(prevCommentsSelected);
      // if (attachments_urls && attachments_urls?.length >= 0) {
      //   let updatedImages = attachments_urls.map((img) => {
      //     const { id, url } = img;
      //     let name = url.split('/')[url.split('/').length - 1].split('?')[0];
      //     return { key: id, image: url, isLocal: false, name };
      //   });

      // let pdfName = pdf_path
      //   .split('/')
      //   [pdf_path.split('/').length - 1].split('?')[0];

      // let img = { key: pdfName, pdf_path, isLocal: false, name: pdfName };

      // setDocuments([img]);

      // let selected = learningActivities?.map((item) => item.learning_activity);
      // setSelectedActivities(selected);
    }
    // }
    // setUtils(utilsData);
  }, [currentNpoObj]); // utilsData

  useEffect(() => {
    setLoading(createNpoLoading);
    // if (createNpoLoading) {
    //   setIsUserImgSelected(false);
    // }
  }, [createNpoLoading]);

  useEffect(() => {
    if (pathname.includes("create") && currentNpoObj?._id === undefined) {
      setIsEdit(true);
    } else {
      setIsEdit(npoDetailsIsEdit);
    }
  }, [npoDetailsIsEdit, currentNpoObj]);

  const onFinish = (values) => {
    const { ...res } = values;

    const deletedStickers = prevSelected
      .filter((id) => !stickersSelected.includes(id))
      .map((id) => ({ id, action: "remove" }));

    // Find new added stickers
    const addedStickers = stickersSelected
      .filter((id) => !prevSelected.includes(id))
      .map((id) => ({ id, action: "add" }));

    let learning_stickers = [...deletedStickers, ...addedStickers];

    const deletedComments = prevCommentsSelected
      .filter((id) => !commentsSelected.includes(id))
      .map((id) => ({ id, action: "remove" }));

    // Find new added comments
    const addedComments = commentsSelected
      .filter((id) => !prevCommentsSelected.includes(id))
      .map((id) => ({ id, action: "add" }));

    let learning_comments = [...deletedComments, ...addedComments];

    isNpoUpdate
      ? dispatch(
          updateNpo({
            ...res,
            // ...(documents && { documents }),
            learning_stickers,
            learning_comments,
            id: currentNpoObj._id,
          })
        )
      : dispatch(
          createNpo({
            ...res,
            learning_stickers,
            learning_comments,
          })
        );
  };
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const handleSave = () => {
    form.submit();
  };

  // const capitalizeFirstLetter = (str) =>
  //   str && str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      <Row>
        <Col span={24} className="heading-col">
          <Row align="middle" justify="space-between">
            <Col>
              <div>
                <span className="heading-span">Learning Activity Details</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                loading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon width={16} height={16} onClick={handleSave} />
                )
              ) : (
                canUpdate && (
                  <EditIcon
                    width={16}
                    fill="#e21c21"
                    height={16}
                    onClick={() => dispatch(setNpoDetailsIsEdit(true))}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section project-details-section ${
            !isEdit && "is-edit"
          }`}
        >
          <Row justify="center">
            <Col md={24} lg={23} xl={20} xxl={20}>
              <Form
                {...formItemLayout}
                form={form}
                name="user-details"
                onFinish={onFinish}
                initialValues={currentNpo}
                scrollToFirstError
                labelWrap={true}
                layout="horizontal"
                className="user-detail-form"
                requiredMark={false}
                style={{
                  marginTop: "10px",
                }}
              >
                <Row gutter={[20, 10]}>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                      name="name"
                      label="Title"
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder="Learning Activity Title"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                        { pattern: isValidNumber, message: "Invalid" },
                      ]}
                      name="passing_percentage"
                      label="Passing Marks *"
                    >
                      <InputNumber
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder="Enter passing marks"
                        min={0}
                        max={100}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                        { pattern: isValidNumber, message: "Invalid" },
                      ]}
                      name="sequence"
                      label="Sequence *"
                    >
                      <InputNumber
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder="Enter sequence number"
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                      name="stream"
                      label="Stream *"
                    >
                      <AutoComplete
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        options={streamsOptions}
                        placeholder="Enter Stream"
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      ></AutoComplete>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="final_assessment_required"
                      label="Final assessment required *"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        dropdownClassName="select-dropdown-custom"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "ant-layout-content layout-content site-layout-background custom-textarea"
                          )[0]
                        }
                        showSearch
                        placeholder="Final assessment required"
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        optionFilterProp="children"
                        optionLabelProp="label"
                        filterOption={(input, option) =>
                          option?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]?.map(({ label, value }) => {
                          return (
                            <Option value={value} label={label}>
                              {label || ""}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="peer_learning"
                      label="Peer Learning Required *"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        dropdownClassName="select-dropdown-custom"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "ant-layout-content layout-content site-layout-background custom-textarea"
                          )[0]
                        }
                        showSearch
                        placeholder="Peer learning required"
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        optionFilterProp="children"
                        optionLabelProp="label"
                        filterOption={(input, option) =>
                          option?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]?.map(({ label, value }) => {
                          return (
                            <Option value={value} label={label}>
                              {label || ""}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Stickers">
                      <Select
                        mode="multiple"
                        size="middle"
                        style={{
                          width: "100%",
                        }}
                        disabled={!isEdit}
                        placeholder={`${!isEdit ? "" : "Select Stickers"}`}
                        value={stickersSelected}
                        onChange={(e) => setStickersSelected(e)}
                        options={allStickersOptions}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Comments">
                      <Select
                        mode="multiple"
                        size="middle"
                        style={{
                          width: "100%",
                        }}
                        disabled={!isEdit}
                        placeholder={`${!isEdit ? "" : "Select Comments"}`}
                        value={commentsSelected}
                        onChange={(e) => setCommentsSelected(e)}
                        options={allCommentsOptions}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </Form.Item>
                  </Col>
                  {/* {isNpoUpdate && (
                    <Col span={12}>
                      <Form.Item label='Learning Activities'>
                        <Select
                          mode='multiple'
                          size='small'
                          style={{
                            width: '100%',
                          }}
                          disabled={!isEdit}
                          placeholder='Select Learning Activities'
                          value={selectedActivities}
                          onChange={handleChange}
                          options={learningActivities}
                        />
                      </Form.Item>
                    </Col>
                  )} */}
                  {/* <Col span={24}>
                    <AttachmentsSection
                      isEdit={isEdit}
                      loading={loading}
                      documents={documents}
                      setDocuments={setDocuments}
                      currentNpo={currentNpo}
                    />
                  </Col> */}

                  {/* <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='npo_region_id'
                      label='Region'
                    >
                      <Select
                        style={{
                          width: '100%',
                        }}
                        disabled={!isEdit || !canUpdate}
                        readOnly={true}
                        className='department-select'
                        dropdownClassName='select-dropdown-custom'
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            'ant-layout-content layout-content site-layout-background custom-textarea'
                          )[0]
                        }
                        showSearch
                        placeholder='Select Region'
                        optionFilterProp='children'
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allNpoRegions.map(({ id, name }) => {
                          return (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='status_id'
                      label='NPO Status'
                    >
                      <Select
                        style={{
                          width: '100%',
                        }}
                        disabled={!isEdit || !canUpdate}
                        readOnly={true}
                        className='department-select'
                        dropdownClassName='select-dropdown-custom'
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            'ant-layout-content layout-content site-layout-background custom-textarea'
                          )[0]
                        }
                        showSearch
                        placeholder='Select NPO Status'
                        optionFilterProp='children'
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allNpoStatuses.map(({ id, name }) => {
                          return (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='contact_name'
                      label='Director Name'
                    >
                      <Input
                        readOnly={loading}
                        disabled={
                          !isEdit ||
                          // isNpoUpdate ||
                          !canUpdate
                        }
                        placeholder='Director Name'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='contact_no'
                      label='Director Contact Number'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='Director Contact Number'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='email'
                      label='Director Email'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate} // || isUserUpdate
                        placeholder='Director Email'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='manager_name'
                      label='PROGRAMME MANAGER NAME'
                    >
                      <Input
                        readOnly={loading}
                        disabled={
                          !isEdit ||
                          // isNpoUpdate ||
                          !canUpdate
                        }
                        placeholder='Programme Manager Name'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='manager_no'
                      label='PROGRAMME MANAGER NUMBER'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='Programme Manager Number'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='manager_email'
                      label='PROGRAMME MANAGER EMAIL'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate} // || isUserUpdate
                        placeholder='Programme Manager Email'
                      />
                    </Form.Item>
                  </Col> */}
                  {/* <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='project_type'
                      label='Type of NPO'
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Type of NPO'
                      />
                    </Form.Item>
                  </Col> */}

                  {/* <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='onboard_date'
                      label='NPO Onboard Date'
                    >
                      <DatePicker
                        style={{
                          width: '100%',
                        }}
                        format='D-MM-YYYY'
                        allowClear={false}
                        suffixIcon={null}
                        placeholder='NPO Onboard Date'
                        disabled={!isEdit || !canUpdate || loading}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={16} lg={16} xl={16} xxl={16}>
                    <Form.Item
                      name='address'
                      label='NPO Address'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='NPO Address'
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="empty-div"></div>
    </>
  );
};

export default Details;
