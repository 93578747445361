import React from 'react';
import { Result } from 'antd';
// import { useNavigate } from 'react-router-dom';

const Sorry = () => {
  // const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Result
        status='warning'
        title='Sorry to See You Rejecting the Invitation!'
        subTitle='We respect your decision. If you change your mind, feel free to reach out to us.'
        // extra={[
        //   <Button
        //     type="default"
        //     key="contact"
        //     onClick={() => navigate('/contact')}
        //   >
        //     Contact Us
        //   </Button>,
        //   <Button
        //     type="primary"
        //     key="home"
        //     onClick={() => navigate('/')}
        //   >
        //     Go to Home
        //   </Button>,
        // ]}
      />
    </div>
  );
};

export default Sorry;
