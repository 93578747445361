import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Button, Result, message, Form, Input, Select, Checkbox } from 'antd';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';
import SpinnerComponent from '../../components/spinner';
// import axios from 'axios';

const { Option } = Select;

const TutorInvite = () => {
  const { invitation_id } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  //   const [inviteStatus, setInviteStatus] = useState(null);
  //   const [isModalVisible, setIsModalVisible] = useState(false);
  //   const [statusToSubmit, setStatusToSubmit] = useState('');
  const [invitationData, setInvitationData] = useState(null);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [form] = Form.useForm();

  // Fetch query parameter 'status'
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status'); // 'accept' or 'reject'
  const tenant = queryParams.get('tenant'); // 'accept' or 'reject'

  const navigate = useNavigate();

  useEffect(() => {
    const verifyInvite = async () => {
      const URL = `${baseURL}/api/v2/admin/tutors/verify-invite/${invitation_id}?status=${status}`;
      try {
        setLoading(true);
        const response = await axios.get(URL, {
          headers: {
            tenant: tenant,
          },
        });

        if (response.status === 200 && response?.data?.data) {
          const { invitationRecord, user } = response.data?.data;
          setInvitationData({ ...invitationRecord, user });
        }
        // setInviteStatus(response.data.message);
      } catch (error) {
        console.log('error', error);
        // setInviteStatus(
        //   error.response?.data?.message || 'Error verifying the invitation.'
        // );
      } finally {
        setLoading(false);
      }
    };

    // Only verify automatically if status is in query params
    if (status) {
      verifyInvite();
    }
  }, [invitation_id, location, status, tenant]);

  // Confirm Action
  //   const confirmAction = (actionStatus) => {
  //     setStatusToSubmit(actionStatus);
  //     setIsModalVisible(true); // Show confirmation modal
  //   };

  // Submit Accept/Reject to the backend
  //   const handleConfirmation = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await axios.get(
  //         `${baseURL}/api/v2/tutor/verify-invite/${invitation_id}?status=${statusToSubmit}`
  //       );
  //       //   message.success(response.data.message);
  //       //   setInviteStatus(response.data.message);
  //     } catch (error) {
  //       message.error(
  //         error.response?.data?.message || 'Error updating invitation status.'
  //       );
  //     } finally {
  //       setIsModalVisible(false);
  //       setLoading(false);
  //     }
  //   };

  const handleReject = async () => {
    try {
      setRejectLoading(true);
      await axios.post(
        `${baseURL}/api/v2/admin/tutors/update-invite/${invitation_id}?status=reject`,
        null,
        {
          headers: {
            tenant: tenant,
          },
        }
      );
      //   message.success('You have rejected the invitation successfully.');
      navigate('/invite-rejected');
    } catch (error) {
      console.log('error', error);
      //   message.error(
      //     error.response?.data?.message || 'Error updating invitation status.'
      //   );
    } finally {
      //   setIsModalVisible(false);
      setRejectLoading(false);
    }
  };

  const handleAccept = async (data = {}) => {
    try {
      setRejectLoading(true);
      await axios.post(
        `${baseURL}/api/v2/admin/tutors/update-invite/${invitation_id}?status=${status}`,
        data,
        {
          headers: {
            tenant: tenant,
          },
        }
      );

      //   message.success(`You have ${status}ed the invitation successfully.`);

      navigate('/invite-accepted');
    } catch (error) {
      console.log('error', error);
      message.error(
        error.response?.data?.message || 'Error updating invitation status.'
      );
    } finally {
      //   setIsModalVisible(false);
      setRejectLoading(false);
    }
  };

  const onFinish = async (values) => {
    console.log('Success:', values);
    handleAccept({ ...values, deleted_status: 'active' });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '40vh' }}>
        <SpinnerComponent fontSize={60} />
      </div>
    );
  }

  if (invitationData === null) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Result
          status='404'
          title={`Invitation Not Found!`}
          // subTitle={invitationData.invite_status}
        />
      </div>
    );
  }

  if (invitationData?.invite_status !== 'pending') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Result
          status='403'
          title={`Invitation Status`}
          subTitle={`Invitation has already been ${invitationData.invite_status}`}
        />
      </div>
    );
  }

  if (status === 'reject') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <h1>Are you sure you want to reject your tutor invitation!</h1>

          <Button
            onClick={handleReject}
            disabled={rejectLoading}
            style={{
              backgroundColor: 'red',
              color: 'white',
            }}
            loading={rejectLoading}
          >
            Reject
          </Button>
        </div>
      </div>
    );
  }

  if (status === 'accept') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        {invitationData?.user ? (
          <div style={{ textAlign: 'center' }}>
            <h1>Are you sure you want to accept your tutor invitation?</h1>
            <Button
              onClick={() => {
                handleAccept({});
              }}
              disabled={rejectLoading}
              loading={rejectLoading}
            >
              Accept
            </Button>
          </div>
        ) : (
          <Form
            form={form}
            name='Tutor Invite Form'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              tutor_email: invitationData.tutor_email,
              tutor_name: invitationData.tutor_name,
              // roles: 'tutor',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            style={{
              minWidth: '600px',
            }}
          >
            <h1 style={{ textAlign: 'center' }}>
              Please fill the following information!
            </h1>
            <Form.Item
              label='Name'
              name='tutor_name'
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label='Email'
              name='tutor_email'
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input disabled />
            </Form.Item>

            {/* <Form.Item label='Role' name='roles' hidden>
              <Select placeholder='' allowClear={false} disabled>
                <Option value='tutor'>Tutor</Option>
              </Select>
            </Form.Item> */}

            <Form.Item
              label='Password'
              name='password'
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label='Gender'
              name='gender'
              rules={[
                { required: true, message: 'Please select your gender!' },
              ]}
            >
              <Select placeholder='Select gender' allowClear={false}>
                <Option value='M'>Male</Option>
                <Option value='F'>Female</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name='accept_terms'
              valuePropName='checked'
              wrapperCol={{ offset: 4, span: 20 }}
            >
              <Checkbox
                onChange={(e) => {
                  setAcceptTerms(e.target.checked);
                }}
              >
                I accept the
              </Checkbox>
              <a href='/terms' target='_blank'>
                terms and conditions
              </a>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
              <Button htmlType='submit' disabled={!acceptTerms}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    );
  }

  return <></>;
};

export default TutorInvite;
