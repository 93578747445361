import React, { useState, useEffect } from 'react';
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  BellFilled,
} from '@ant-design/icons';
import { Avatar, Layout, Menu, Badge } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import Logo from '../../../assets/Color_Logo.png';
import { ReactComponent as LogoutIcon } from '../../../assets/logout.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import useProfile from './useSidebar';
import { ReactComponent as DotIcon } from '../../../assets/dot.svg';
import { ReactComponent as UserIcon } from '../../../assets/user-icon.svg';
// import collapsedIcon from '../../../assets/collapsed-logo.png';
import UseWindowSize from './useWindowSize';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, setNavbarCollapsed } from '../../login/redux/actions';
import SelectProjectsPopup from './selectProjectsPopover';
// import NotificationChannel from '../notifications/notificationChannel';
import Notifications from '../notifications/index';
// import { fetchUnreadNotifications } from '../notifications/notificationsRedux/notificationActions';
import { fetchAllUserProjects } from '../../UserManager/redux/actions';

const SidebarComponent = (props) => {
  const { Sider } = Layout;
  const [shouldCollapse, setShouldCollapse] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logoutLoading } = useSelector((state) => state?.authReducer);
  const { unreadNotifications } = useSelector(
    (state) => state.notificationsReducer
  );

  const dispatch = useDispatch();
  const [menuItemsData] = useProfile();
  const [SelectProjectsModalvisible, setSelectProjectsModalVisible] =
    useState(false);
  const [notificationModalvisible, setNotificationModalVisible] =
    useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const { projects } = useSelector((state) => state.authReducer);
  const { user_projects } = useSelector((state) => state.userManagerReducer);
  const { npos } = useSelector((state) => state.npoSetupReducer);

  const screenWidth = UseWindowSize();

  useEffect(() => {
    if (screenWidth < 992) {
      setShouldCollapse(false);
      setCollapsed(true);
      dispatch(setNavbarCollapsed(true));
    } else {
      setShouldCollapse(true);
    }
  }, [screenWidth]);

  useEffect(() => {
    // dispatch(fetchUnreadNotifications());
    // dispatch(
    //   fetchAllUserProjects({ search: {}, sortedInfo: null, user_id: user.id })
    // );
  }, [location.pathname]);

  let ls = localStorage.getItem('lsselectedProjects');
  useEffect(() => {
    if (ls === null) {
      setSelectedProjects([]);
    } else {
      setSelectedProjects(JSON.parse(ls));
    }
  }, [location.pathname, ls, projects, user_projects, npos]);

  const isSelected = (id, eventKey) => {
    let isProjectControl = location.pathname.includes(`npo/${eventKey}`);
    if (isProjectControl) {
      let urlID = location.pathname.split('/').slice(-1)[0];
      return urlID == id ? true : false;
    } else {
      return false;
    }
  };

  const handleSelectedKey = () => {
    if (location.pathname.includes('/curriculum-management')) {
      return '/curriculum-management';
    }
    if (location.pathname.includes('/settings/users-manager')) {
      return '/settings/users-manager';
    }
    if (location.pathname.includes('/settings/users-manager')) {
      return '/settings/users-manager';
    }
    if (location.pathname.includes('/settings/contact-type')) {
      return '/settings/contact-type';
    }
    if (location.pathname.includes('/settings/product-type')) {
      return '/settings/product-type';
    }

    if (location.pathname.includes('/settings/lead-statuses')) {
      return '/settings/lead-statuses';
    }
    if (location.pathname.includes('/settings/quote-statuses')) {
      return '/settings/quote-statuses';
    }

    if (location.pathname.includes('/settings/form-manager/form-setup')) {
      return '/settings/form-manager/form-setup';
    }
    if (
      location.pathname.includes('/settings/form-manager/value-added-services')
    ) {
      return '/settings/form-manager/value-added-services';
    }

    if (location.pathname === '/') {
      let path = '/profile';
      navigate(path);
      return path;
    }

    return location.pathname;
  };

  const handleLogout = () => {
    dispatch(logoutUser({ navigate, id: user.id }));
  };

  const NposSubItems = (props) => {
    const { eventKey, id } = props;
    let link = `/npo/${eventKey}/${id}`;
    let className = isSelected(id, eventKey) ? 'project-doc-selected' : '';
    let menukey = `npo/${id}/${eventKey}`;

    return (
      <Menu.Item
        onClick={() => navigate(link)}
        className={`subitems-custom projects-subitems-custom ${className}`}
        key={menukey} // link
        style={{
          padding: collapsed ? `0px !important` : '0px 15px 0px 45px',
          paddingLeft: collapsed ? '10px' : '45px',
          textTransform: 'capitalize',
        }}
        icon={
          <DotIcon
            fill='#ffffff'
            className='dot-icon'
            style={{
              margin: collapsed ? '0px' : '0px 5px 0px 0px',
            }}
          />
        }
      >
        {eventKey}
      </Menu.Item>
    );
  };

  const ProjectsList = ({ item }) => {
    return selectedProjects.map((project, i) => {
      const { id, npo } = project;
      const { name, npo_region } = npo;
      // let className = isSelected(id) ? 'project-doc-selected' : '';
      let parentClass = isSelected(id) ? 'project-doc-selected-parent' : '';
      // let menukey = `project/${id}/document-control`;

      return (
        <SubMenu
          className={`menu-item-for-top-border${
            collapsed ? '-collapsed' : ''
          } projects-submenu ${parentClass}`} // projects-subitems-custom
          key={id}
          eventKey={id}
          // onTitleClick={(e) => console.log("e", e.key, key)}
          icon={
            <DotIcon
              fill='#ffffff'
              className='projects-submenu-bullet'
              style={{
                width: '10px',
                height: '10px',
                fontSize: '10px',
                margin: collapsed ? '0px' : '0px 15px 0px 0px',
              }}
            />
          }
          title={
            // !collapsed && (
            <div style={{ height: '100%' }}>
              <div
                className='project-name'
                style={{ width: '100%', lineHeight: '20px' }}
              >
                <span
                  style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                  }}
                >
                  {name}
                </span>
              </div>
              <div
                className='client-name'
                style={{
                  width: '100%',
                  lineHeight: '20px',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '11px',
                  }}
                >
                  {npo_region?.name}
                </span>
              </div>
            </div>
            // )
          }
        >
          <NposSubItems key='learners' id={id} />
          <NposSubItems key='coaches' id={id} />
          <NposSubItems key='sites' id={id} />
          <NposSubItems key='classes' id={id} />
        </SubMenu>
      );
    });
  };

  const projectControlMenuItem = (item) => {
    const { link } = item;

    return (
      <>
        <Menu.Item
          onClick={() =>
            setSelectProjectsModalVisible(!SelectProjectsModalvisible)
          }
          className={`subitems-custom select-project`}
          key={link}
          style={{
            padding: collapsed ? `0px !important` : '10px 15px 10px 45px',
            paddingLeft: collapsed ? '10px' : '42px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <span
                style={{
                  paddingLeft: '42px',
                }}
              >
                Select Organizations
              </span>
            </div>
            <div>
              <DownOutlined className='dropdown-icon' />
            </div>
          </div>
        </Menu.Item>
        {!SelectProjectsModalvisible && <ProjectsList item={item} />}
      </>
    );
  };

  const menuSubItems = (item) => {
    const { name, link, hasSubItems } = item;
    if (name === 'divider') {
      return '';
      // return <Divider className='sidebar-settings-divider' />;
    }

    if (hasSubItems) {
      const { name, key, subItems } = item;
      return (
        <SubMenu
          key={key}
          icon={
            <DotIcon
              fill='#ffffff'
              className='dot-icon'
              style={{
                margin: collapsed ? '0px 2px 0px 3px' : '0px 10px 0px 10px',
              }}
            />
          }
          title={
            <span className={`${key} ${'settings-title-span'}`}>{name}</span>
          }
        >
          {subItems?.map((el) => {
            const { hasPermission } = el;

            return hasPermission && menuSubItems(el);
          })}
        </SubMenu>
      );
    }

    let isFormSubitem = link.includes('form-manager');
    const { pathname } = location;
    let clsName = pathname.includes(item.link) && 'selected';

    return (
      <Menu.Item
        onClick={() => navigate(link)}
        className={`subitems-custom ${
          isFormSubitem && !collapsed && 'form-subitem'
        } ${clsName}`}
        key={link}
        style={{
          padding: collapsed ? `0px !important` : '10px 15px 10px 45px',
          paddingLeft: collapsed ? '10px' : '45px',
        }}
        icon={
          !isFormSubitem && (
            <DotIcon
              fill='#ffffff'
              className='dot-icon'
              style={{
                margin: collapsed ? '0px' : '0px 5px 0px 0px',
              }}
            />
          )
        }
      >
        {isFormSubitem && `- `} {name}
      </Menu.Item>
    );
  };

  return (
    <>
      {/* <NotificationChannel /> */}
      <Notifications
        visible={notificationModalvisible}
        setVisible={setNotificationModalVisible}
      />
      <SelectProjectsPopup
        visible={SelectProjectsModalvisible}
        setVisible={setSelectProjectsModalVisible}
        setSelectedProjects={setSelectedProjects}
        selectedProjects={selectedProjects}
      />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={collapsed ? '70px' : `260px`}
        className={`main-sidebar${collapsed ? `-collapsed` : ''} `}
      >
        {shouldCollapse && (
          <span
            onClick={() => {
              setCollapsed(!collapsed);
              dispatch(setNavbarCollapsed(!collapsed));
            }}
            className='sidebar-pin-icon'
          >
            {collapsed ? <RightOutlined /> : <LeftOutlined />}
          </span>
        )}
        <div
          className='trigger sidebar-child-div'
          // onClick={() => shouldCollapse && setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <div
              className='nav-collapsed-img-div'
              style={{
                margin: collapsed ? '15px 0' : '0px 10px',
              }}
              onClick={() => navigate('/')}
            >
              <img src={Logo} alt='' />
            </div>
          ) : (
            <div className='nav-uncollapsed-img-div'>
              <img
                src={Logo}
                alt='Logo'
                style={{
                  margin: collapsed && '0px !important',
                }}
                onClick={() => navigate('/')}
              />
            </div>
          )}
        </div>
        <Menu
          theme='light'
          mode='inline'
          className='sidebar-custom'
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[handleSelectedKey()]}
        >
          {menuItemsData.map((el) => {
            const {
              key,
              src,
              SvgImg,
              name,
              link,
              hasSubItems,
              subItems,
              hasPermission,
              tenant = false,
            } = el;
            // console.log('el', el);
            let topBorder = 'menu-item-for-top-border';
            return hasSubItems && hasPermission ? (
              <SubMenu
                className={`${topBorder} ${collapsed ? '-collapsed' : ''}`}
                key={key}
                icon={
                  <SvgImg
                    className='project-icon'
                    style={{
                      margin: collapsed ? '0px' : '0px 5px 0px 0px',
                    }}
                  />
                }
                title={
                  !collapsed && (
                    <span
                      className={` ${
                        key === 'project-control' ? '' : 'settings-title-span'
                      }`}
                    >
                      {name}
                    </span>
                  )
                }
              >
                {subItems?.map((el) => {
                  const { hasPermission, key } = el;

                  return (
                    hasPermission &&
                    (key === 'project-control'
                      ? projectControlMenuItem(el)
                      : menuSubItems(el))
                  );
                })}
              </SubMenu>
            ) : name === 'notification' ? (
              !shouldCollapse && (
                <Menu.Item
                  className='sidebar-notification-menu-item'
                  onClick={(e) => {
                    //   dispatch(fetchNotificationsList());
                    setNotificationModalVisible(!notificationModalvisible);
                  }}
                  key={'/notification'}
                  icon={
                    <Badge count={unreadNotifications} overflowCount={9}>
                      <BellFilled />
                    </Badge>
                  }
                ></Menu.Item>
              )
            ) : (
              el?.hasPermission && (
                <Menu.Item
                  onClick={() => navigate(link)}
                  key={link}
                  className={
                    link === '/profile'
                      ? `sidebar-custom-menuitem menu-item-for-top-border${
                          collapsed ? '-collapsed' : ''
                        }`
                      : `${collapsed ? '-collapsed' : ''}`
                  }
                  icon={
                    link === '/profile' ? (
                      <div className='sidebar-profile-icon'>
                        <Avatar
                          shape={'circle'}
                          style={{
                            margin: collapsed ? '0px' : '0px 5px 0px 0px',
                          }}
                          src={src ? src : <UserIcon />}
                        />
                      </div>
                    ) : (
                      <Avatar
                        className='profile-avatar'
                        shape={'square'}
                        style={{
                          margin: collapsed ? '0px' : '0px 5px 0px 0px',
                        }}
                        src={
                          SvgImg ? <SvgImg className='profile-svg-icon' /> : src
                        }
                      />
                    )
                  }
                >
                  {link === '/profile' ? (
                    <div className='profile-menu-div'>
                      <div className='profile-username-div'>
                        <p>{name}</p>
                        <p className='tenant'>{tenant}</p>
                        <span>{el.role}</span>
                      </div>
                      {!collapsed && (
                        <div
                          style={{
                            paddingRight: 12,
                            zIndex: 999999999999999,
                          }}
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          //   // dispatch(fetchNotificationsList());
                          //   setNotificationModalVisible((e) => !e);
                          // }}
                        >
                          {/* <Badge count={unreadNotifications} overflowCount={9}>
                            <BellFilled className='notification-icon' />
                          </Badge> */}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='profile-menu-div'>{name}</div>
                  )}
                </Menu.Item>
              )
            );
          })}
          <Menu.Item
            className={`sidebar-custom-menuitem ${
              collapsed ? '-collapsed' : ''
            }`}
            style={{
              cursor: 'default',
            }}
            key={'none'}
          ></Menu.Item>
          <Menu.Item
            onClick={() => !logoutLoading && handleLogout()}
            className={`sidebar-custom-menuitem menu-item-for-top-border${
              collapsed ? '-collapsed' : ''
            } logout-menu-item`}
            key={'logout'}
            icon={<LogoutIcon />}
          >
            Logout {logoutLoading && '...'}
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};
export default SidebarComponent;
