import React, { useEffect, useState } from 'react';
import { Col, Input, Row, message, Select, Form } from 'antd';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import sampleImg from '../../../assets/user-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUser,
  // fetchUserDetailsUtils,
  // sendDetailsToUser,
  setUserDetailsIsEdit,
  updateUser,
} from '../redux/actions';
import CreatePasswordModal from './createPasswordModal';
// import SpinnerComponent from '../../../components/spinner';
// import { isValidNumber } from '../../../globalUtils/axiosConfig';
// import { capitalizeFirstLetterofEachWord } from '../../../globalUtils/capitalizeFirstLetterofEachWord';
import UpdatePasswordModal from './updatePasswordModal';
// import UseGetUserRole from '../../../components/useGetUserRole';
import {
  genderOptions,
  // statusOptions,
  // systemUsersOptions,
  user_roles,
  yearsLevels,
} from './utils';
import { baseURL } from '../../../globalUtils/axiosConfig';
// import AMultiSelectControlledOutside from '../../../components/AMultiSelectControlledOutside';
// import AMultiSelect from '../../../components/AMultiSelect';
// import { getOptions } from '../../Sales/utils';
// const regex = /^[-+\d\s]+$/;
const regex = /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;

const UserDetailsSection = ({
  isUserUpdate,
  isProfileCase,
  canUpdate,
  userType,
  cannotUpdate,
  showHideFields,
  yearsLevelOption,
  prevSelected,
  options,
  allGuardians,
  allStudents,
  allUsers,
  curriculumsOptions,
  classesOptions,
  tutorsOptions,
}) => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [userProfileImg, setUserProfileImg] = useState(null);
  const [isUserImgSelected, setIsUserImgSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [curriculumSelected, setCurriculumSelected] = useState([]);
  // const [processCheckedList, setProcessCheckedList] = useState([]);
  const [classesSelected, setClassesSelected] = useState([]);
  // const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fieldsFormRef] = Form.useForm();
  const { Option } = Select;
  const {
    createUserLoading,
    currentUserObj,
    userDetailsIsEdit,
    // sendDetailLoading,
  } = useSelector((state) => state.userManagerReducer);

  // const { allUsers } = useSelector((state) => state.userManagerReducer);

  // const [isSuperAdmin] = UseGetUserRole();

  useEffect(() => {
    form.setFieldsValue(currentUserObj);
    setCurrentUser(currentUserObj);

    let isTeacher = currentUserObj?.roles?.includes('teacher') || false;
    let isStudent = currentUserObj?.roles?.includes('student') || false;

    if (isStudent) {
      let prevCurriculum = currentUserObj?.curriculum_students || [];
      let ids = prevCurriculum.map((el) => el.curriculum);
      setCurriculumSelected(ids);
    }

    if (isTeacher || isStudent) {
      let prevClasses = currentUserObj?.class_enrolments || [];
      let classesIds = prevClasses.map((el) => el.class._id);
      setClassesSelected(classesIds);
    }
  }, [currentUserObj]);

  useEffect(() => {
    setLoading(createUserLoading);
    // if (createUserLoading) {
    //   setIsUserImgSelected(false);
    // }
  }, [createUserLoading]);

  useEffect(() => {
    setOptionsSelected(prevSelected);
  }, [prevSelected]);

  const changeHandler = (event) => {
    const image = event.target.files[0];
    if (!image) {
      return false;
    }
    if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
      message.error('Photo should be png or jpg format.');
      return false;
    }
    if (image.size > 5120000) {
      message.error('Photo size should be less than 5MB.');
      return false;
    }
    setUserProfileImg(event.target.files[0]);
    // handleChange("image", event.target.files[0]);
    setIsUserImgSelected(true);
  };

  // const onChange = (value) => {
  //   // console.log(`selected ${value}`);
  // };

  const onFinish = (values) => {
    // let field_values = fieldsFormRef.getFieldsValue();

    if (password?.length < 6 && !isUserUpdate) {
      return message.error('Password is required');
    }

    if ((!userProfileImg || userProfileImg?.length < 1) && !isUserUpdate) {
      return message.error('Profile photo is required');
    }

    let prevCurriculum = currentUserObj?.curriculum_students || [];
    prevCurriculum = prevCurriculum.map((el) => el.curriculum);
    // Find deleted curriculum
    const deleted_curriculum = prevCurriculum.filter(
      (userId) => !curriculumSelected.includes(userId)
    );

    // Find new added curriculum
    const added_curriculum = curriculumSelected.filter(
      (userId) => !prevCurriculum.includes(userId)
    );

    // Find deleted users
    const deleted_users = prevSelected.filter(
      (userId) => !optionsSelected.includes(userId)
    );

    // Find new added users
    const added_users = optionsSelected.filter(
      (userId) => !prevSelected.includes(userId)
    );

    let prevClasses = currentUserObj?.class_enrolments || [];
    prevClasses = prevClasses.map((el) => el.class._id);

    // Find deleted classes
    const deleted_classes = prevClasses.filter(
      (userId) => !classesSelected.includes(userId)
    );

    // Find new added classes
    const added_classes = classesSelected.filter(
      (userId) => !prevClasses.includes(userId)
    );

    let roles = currentUserObj.roles;
    let addedStudents = [];
    let addedGuardians = [];
    let deletedStudents = [];
    let deletedGuardians = [];
    let addedClasses = [];
    let deletedClasses = [];

    let isTeacher = roles?.includes('teacher');
    let isStudent = roles?.includes('student');

    if (isStudent) {
      addedGuardians = added_users;
      deletedGuardians = deleted_users;
      // } else if (user_type === 'guardian') {
    } else {
      addedStudents = added_users;
      deletedStudents = deleted_users;
    }

    if (isStudent || isTeacher) {
      addedClasses = added_classes;
      deletedClasses = deleted_classes;
    }

    isUserUpdate
      ? dispatch(
          updateUser({
            ...values,
            ...(password.length > 5 && { password }),
            ...(userProfileImg && { image_path: userProfileImg }),
            id: currentUserObj._id,
            addedStudents,
            addedGuardians,
            deletedStudents,
            deletedGuardians,
            deleted_curriculum,
            added_curriculum,
            addedClasses,
            deletedClasses,
          })
        )
      : dispatch(
          createUser({
            image_path: userProfileImg,
            password,
            ...values,
            added_curriculum,
          })
        );
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleSave = async (key) => {
    try {
      const row = await form.validateFields();
      const formFields = await fieldsFormRef.validateFields();

      form.submit();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      // const { errorFields } = errInfo;
      // errorFields.forEach((el) => {
      //   el.errors[0] !== 'Required!' && message.error(el.errors[0]);
      // });
    }
  };

  function capitalizeFirstLetters(str) {
    return str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  let maxRule = [
    {
      max: 13,
      message: 'ID Number too long!',
    },
  ];

  let numRule = [
    {
      pattern: regex,
      message: 'Invalid!',
    },
  ];

  // let hideText = '**********';

  // let HideField = ({ label }) => {
  //   return (
  //     <Form.Item name={null} label={label}>
  //       <Input
  //         readOnly={true}
  //         value={hideText}
  //         defaultValue={hideText}
  //         disabled={true}
  //       />
  //     </Form.Item>
  //   );
  // };
  const InputComponent = ({ name, label, placeholder, required }) => {
    let requiredRule = { required: required, message: 'Required!' };
    let rulesUpdated = name === 'id_number' ? maxRule : [requiredRule];
    let rulesFinal =
      name === 'contact_no' ? [...rulesUpdated, ...numRule] : rulesUpdated;
    let disabled = isUserUpdate && name === 'user_id';
    return (
      <Col md={12} lg={12} xl={8} xxl={8}>
        {/* {showHideFields && hideKeys.includes(name) ? (
          <HideField label={label} />
        ) : ( */}
        <Form.Item rules={rulesFinal} name={name} label={label}>
          <Input
            readOnly={loading}
            disabled={
              !userDetailsIsEdit || !canUpdate || cannotUpdate || disabled
            }
            placeholder={placeholder}
          />
        </Form.Item>
        {/* )} */}
      </Col>
    );
  };

  const StaticOptionsSelect = ({
    name,
    label,
    placeholder,
    options,
    disabled = false,
  }) => {
    return (
      <>
        <Col md={12} lg={12} xl={8} xxl={8}>
          {/* {showHideFields && hideKeys.includes(name) ? (
            <HideField label={label} />
          ) : ( */}
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Required!',
              },
            ]}
            name={name}
            label={label}
          >
            <Select
              style={{
                width: '100%',
              }}
              disabled={
                !userDetailsIsEdit || !canUpdate || cannotUpdate || disabled
              }
              className='department-select'
              dropdownClassName='select-dropdown-custom'
              getPopupContainer={() =>
                document.getElementsByClassName(
                  'ant-layout-content layout-content site-layout-background custom-textarea'
                )[0]
              }
              showSearch
              placeholder={placeholder}
              optionFilterProp='children'
              // onSearch={onSearch}
              filterOption={(input, option) => {
                const { children } = option;
                let isArray = Array.isArray(children);
                let str = isArray ? children.join('') : children;
                return str
                  .toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase());
              }}
            >
              {options.map(({ label, value }) => {
                return (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {/* )} */}
        </Col>
      </>
    );
  };

  // const DynamicOptionsSelect = ({ name, label, placeholder, options }) => (
  //   <Col md={12} lg={12} xl={8} xxl={8}>
  //     {/* {showHideFields && hideKeys.includes(name) ? (
  //       <HideField label={label} />
  //     ) : ( */}
  //     <Form.Item
  //       rules={[
  //         {
  //           required: true,
  //           message: 'Required!',
  //         },
  //       ]}
  //       name={name}
  //       label={label}
  //     >
  //       <Select
  //         style={{
  //           width: '100%',
  //         }}
  //         disabled={!userDetailsIsEdit || !canUpdate || cannotUpdate}
  //         className='department-select'
  //         dropdownClassName='select-dropdown-custom'
  //         getPopupContainer={() =>
  //           document.getElementsByClassName(
  //             'ant-layout-content layout-content site-layout-background custom-textarea'
  //           )[0]
  //         }
  //         showSearch
  //         placeholder={placeholder}
  //         optionFilterProp='children'
  //         // onSearch={onSearch}
  //         filterOption={(input, option) =>
  //           option.children
  //             .toLocaleLowerCase()
  //             .includes(input.toLocaleLowerCase())
  //         }
  //       >
  //         {options.map(({ label, value }) => {
  //           return (
  //             <Option key={value} value={value}>
  //               {label}
  //             </Option>
  //           );
  //         })}
  //       </Select>
  //     </Form.Item>
  //     {/* )} */}
  //   </Col>
  // );

  // const onFinishFields = (values) => {
  //   console.log('values', values);
  // };

  // let processing_steps = [];

  const roles = Form.useWatch('roles', form);
  // let canSendDetails =
  //   currentUserObj?._id &&
  //   !isProfileCase &&
  //   !userDetailsIsEdit &&
  //   currentUserObj.user_status === 'active' &&
  //   canUpdate;

  const emailRegex =
    /^[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // const handleSelectOnchange = (value) => {
  //   if (value.includes('all')) {
  //     setProcessCheckedList([...processing_steps.map((item) => item.value)]);
  //     value = processing_steps.map((item) => item.value);
  //   } else if (value.includes('not-all')) {
  //     setProcessCheckedList([]);
  //     value = [];
  //   } else {
  //     setProcessCheckedList(value);
  //   }
  // };

  const handleChange = (value) => {
    setOptionsSelected(value);
  };

  // let listType =
  //   currentUserObj.user_type === 'student' ? 'guardian' : 'student';
  let isStudent = currentUserObj?.roles?.includes('student');

  const getRolesOptions = () =>
    user_roles.map((el) => {
      let isStudent = roles?.includes('student');
      let isGuardian = roles?.includes('guardian');

      if (el.value === 'guardian' && isStudent) {
        return {
          ...el,
          disabled: true,
        };
      }

      if (el.value === 'student' && isGuardian) {
        return {
          ...el,
          disabled: true,
        };
      }

      return el;
    });

  const SelectFormItem = (props) => {
    const { label, name, required, options, placeholder } = props;
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: required,
            message: 'Required!',
          },
        ]}
      >
        <Select
          size='middle'
          style={{
            width: '100%',
          }}
          disabled={!userDetailsIsEdit}
          placeholder={placeholder}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          optionFilterProp='children'
          optionLabelProp='label'
        >
          {options.map(({ label, value }) => {
            return (
              <Option key={value} value={value} label={label}>
                {label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };

  return (
    <>
      {isUserUpdate ? (
        <UpdatePasswordModal
          isVisible={passwordModalOpen}
          setVisible={setPasswordModalOpen}
          email={currentUserObj.email}
          isClient={false}
        />
      ) : (
        <CreatePasswordModal
          setPassword={setPassword}
          isVisible={passwordModalOpen}
          setVisible={setPasswordModalOpen}
          isUpdate={isUserUpdate}
        />
      )}
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>{userType} Details</span>
              </div>
            </Col>
            <Col>
              {userDetailsIsEdit ? (
                // loading ? (
                //   <SpinnerComponent fontSize={14} />
                // ) : (
                <SaveIcon width={16} height={16} onClick={handleSave} />
              ) : (
                // )
                (isProfileCase || canUpdate) && (
                  // !showHideFields &&
                  <EditIcon
                    width={16}
                    fill='#e21c21'
                    height={16}
                    onClick={() => dispatch(setUserDetailsIsEdit(true))}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section ${!userDetailsIsEdit && 'is-edit'}`}
        >
          <Row justify='end'>
            <Col className='required-field-text'>
              {userDetailsIsEdit && '* Required Fields'}
            </Col>
          </Row>
          <Row justify='start' style={{ marginTop: '10px' }}>
            <Col md={5} lg={5} xl={5} xxl={4} className='photo-col'>
              <div>
                <span>PHOTO</span>
                <div
                  className={`img-parent-div ${
                    userDetailsIsEdit && 'edit-image'
                  }`}
                >
                  <div className='img-nested-div'>
                    <img
                      width='100%'
                      height='100%'
                      src={
                        isUserImgSelected
                          ? URL.createObjectURL(userProfileImg)
                          : currentUserObj?.image_path
                          ? `${baseURL}/${currentUserObj?.image_path}`
                          : sampleImg
                      }
                      alt=''
                    />
                  </div>
                  {userDetailsIsEdit && (
                    <div className='edit-icon-div'>
                      <input
                        readOnly={loading}
                        disabled={!userDetailsIsEdit}
                        type='file'
                        name='profileImg'
                        id='profileImg'
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={changeHandler}
                      />
                      <label htmlFor='profileImg'>
                        <EditIcon height={14} width={14} />
                      </label>
                    </div>
                  )}
                </div>
                {userDetailsIsEdit && (
                  <div className='text-div'>
                    <span className='text'>
                      Upload a JPG or PNG file.
                      <br />
                      Recommended image size 800 x 800: Max 5 MB{' '}
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col
              md={userDetailsIsEdit ? 20 : 20} // 19 : 15
              lg={userDetailsIsEdit ? 20 : 20}
              xl={userDetailsIsEdit ? 20 : 20}
              xxl={20}
            >
              <Form
                {...formItemLayout}
                form={form}
                name='user-details'
                onFinish={onFinish}
                initialValues={currentUser}
                scrollToFirstError
                labelWrap={true}
                layout='horizontal'
                className='user-detail-form'
                requiredMark={false}
              >
                <Row gutter={[30, 20]}>
                  <InputComponent
                    name='first_name'
                    label='First Name *'
                    placeholder='First Name'
                    required={true}
                  />
                  <InputComponent
                    name='last_name'
                    label='last name *'
                    placeholder='Last Name'
                    required={true}
                  />
                  <InputComponent
                    name='user_id'
                    label='User ID *'
                    placeholder='User ID'
                    required={true}
                  />
                  {/* <InputComponent
                    name='contact_no'
                    label='contact number'
                    placeholder='Contact Number'
                    required={false}
                  /> */}

                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      name='email'
                      label='Email Address *'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          pattern: emailRegex,
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading || !userDetailsIsEdit || !canUpdate}
                        disabled={
                          !userDetailsIsEdit ||
                          !canUpdate ||
                          cannotUpdate ||
                          isUserUpdate
                        }
                        placeholder='Email Address'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <label>password *</label>
                    <div
                      className='password-div'
                      style={{
                        cursor: !userDetailsIsEdit && 'text',
                      }}
                    >
                      <span
                        onClick={() =>
                          userDetailsIsEdit && setPasswordModalOpen(true)
                        }
                      >
                        {`${isUserUpdate ? 'Change' : 'Create'} Password`}
                      </span>
                    </div>
                  </Col>
                  {/* <InputComponent
                    name='id_number'
                    label='ID NUMBER (Non SA Resident - Passport No)'
                    placeholder='ID Number'
                    required={false}
                  /> */}

                  {/* <InputComponent
                    name='employee_number'
                    label='EMPLOYEE NUMBER'
                    placeholder='Employee Number'
                    required={false}
                  /> */}
                  <StaticOptionsSelect
                    name='gender'
                    label='gender *'
                    placeholder='Select gender'
                    options={genderOptions}
                    required={true}
                  />
                  {/* DynamicOptionsSelect */}

                  {/* {userType.includes('system') && ( */}
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      label='User Roles *'
                      name='roles'
                      rules={[{ required: true, message: 'Required!' }]}
                    >
                      <Select
                        mode='multiple'
                        size='small'
                        style={{
                          width: '100%',
                        }}
                        disabled={
                          !userDetailsIsEdit || !canUpdate || cannotUpdate
                        }
                        placeholder='Select Roles'
                        options={getRolesOptions()}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <SelectFormItem
                      label='Status *'
                      name='deleted_status'
                      required={true}
                      options={[
                        { label: 'Active', value: 'active' },
                        { label: 'Inactive', value: 'inactive' },
                      ]}
                      placeholder='Select status'
                    />
                  </Col>
                  {/* <StaticOptionsSelect
                    name='user_type'
                    label='user type *'
                    placeholder='Select User Type'
                    options={systemUsersOptions}
                    required={true}
                    disabled={
                      !userDetailsIsEdit ||
                      !canUpdate ||
                      cannotUpdate ||
                      isUserUpdate
                    }
                  /> */}
                  {/* )} */}
                  {roles?.includes('student') && (
                    <StaticOptionsSelect
                      name='year_level'
                      label='Year Level *'
                      placeholder='Year Level'
                      options={yearsLevels} // yearsLevelOption
                      required={true}
                    />
                  )}
                  {currentUserObj?._id &&
                    roles?.some((role) =>
                      ['student', 'guardian'].includes(role)
                    ) && (
                      <Col md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item
                          label={
                            roles?.includes('student')
                              ? 'Guardians'
                              : 'Children'
                          }
                        >
                          <Select
                            mode='multiple'
                            size='small'
                            style={{
                              width: '100%',
                            }}
                            disabled={
                              !userDetailsIsEdit || !canUpdate || cannotUpdate
                            }
                            placeholder={`Select ${
                              roles?.includes('student')
                                ? 'Guardians'
                                : 'Children'
                            }`}
                            value={optionsSelected}
                            onChange={handleChange}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            options={allUsers.filter((el) =>
                              !isStudent
                                ? el?.roles?.includes('student')
                                : !el?.roles?.includes('student')
                            )}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  {/* {roles?.includes('student') && (
                    <Col md={12} lg={12} xl={8} xxl={8}>
                      <Form.Item label='Curriculums'>
                        <Select
                          mode='multiple'
                          size='small'
                          style={{
                            width: '100%',
                          }}
                          disabled={
                            !userDetailsIsEdit || !canUpdate || cannotUpdate
                          }
                          placeholder='Select Curriculums'
                          value={curriculumSelected}
                          onChange={(e) => setCurriculumSelected(e)}
                          options={curriculumsOptions}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </Form.Item>
                    </Col>
                  )} */}

                  {currentUserObj?._id &&
                    roles?.some((role) =>
                      ['student', 'teacher'].includes(role)
                    ) && (
                      <Col md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item label='Classes'>
                          <Select
                            mode='multiple'
                            size='small'
                            style={{
                              width: '100%',
                            }}
                            disabled={
                              !userDetailsIsEdit || !canUpdate || cannotUpdate
                            }
                            placeholder='Select Classes'
                            value={classesSelected}
                            onChange={(e) => setClassesSelected(e)}
                            options={classesOptions}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}
                  {currentUserObj?.roles?.includes('student') &&
                    currentUserObj?.tutor && (
                      <Col md={12} lg={12} xl={8} xxl={8}>
                        <Form.Item label='Tutor'>
                          <Select
                            size='small'
                            style={{
                              width: '100%',
                            }}
                            // disabled={
                            //   !userDetailsIsEdit || !canUpdate || cannotUpdate
                            // }
                            placeholder='Select Tutor'
                            value={currentUserObj?.tutor?._id}
                            disabled={true}
                            // onChange={(e) => setClassesSelected(e)}
                            options={tutorsOptions}
                            // filterOption={(input, option) =>
                            //   option.label
                            //     .toLowerCase()
                            //     .indexOf(input.toLowerCase()) >= 0
                            // }
                          />
                        </Form.Item>
                      </Col>
                    )}
                </Row>
              </Form>
            </Col>

            {/* {canSendDetails && (
              <Col span={4}>
                <div className='send-details-parent-div'>
                  <Spin
                    spinning={sendDetailLoading}
                    className={`send-details-div`}
                  >
                    <div
                      className={`send-details-div ${
                        sendDetailLoading && 'disabled-onclick'
                      }`}
                      onClick={() =>
                        dispatch(sendDetailsToUser(currentUserObj.email))
                      }
                    >
                      <span>Send Login</span>
                      <span>Details to User</span>
                    </div>
                  </Spin>
                </div>
              </Col>
            )} */}
          </Row>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};

export default UserDetailsSection;
