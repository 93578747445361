import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Space,
  DatePicker,
  Col,
  Row,
  Button,
  Select,
} from "antd";
import {
  DownOutlined,
  UpOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNpo,
  fetchAllNpos,
  setNpoDetailsIsEdit,
  setStorage,
} from "./redux/actions";
import SpinnerComponent from "../../components/spinner";
import { useNavigate } from "react-router-dom";
// import { baseURL } from "../../../../globalUtils/axiosConfig";
import { baseURL } from "../../globalUtils/axiosConfig";
import _ from "lodash";
// import CustomPagination from '../../components/customPagination/CustomPagination';
// import moment from 'moment';
// import CustomCalendar from '../../components/calendar';
// import AMultiSelect from '../../components/AMultiSelect';
const { Option } = Select;

const BooksTable = ({ canUpdate, canDelete }) => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [statusCheckedList, setStatusCheckedList] = useState([]);
  const [regionsCheckedList, setRegionsCheckedList] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [date, setDate] = useState(null);
  const [npoStatuses, SetNpoStatuses] = useState([]);
  const [npoRegions, SetNpoRegions] = useState([]);

  const { allNpos, fetchNposLoading, deleteNpoLoading, totalRecords, ...rest } =
    useSelector((state) => state.learningActivitiesReducer);

  // const { allNpoStatuses } = useSelector((state) => state.npoStatusReducer);

  // const { allNpoRegions } = useSelector((state) => state.npoRegionsReducer);

  // useEffect(() => {
  //   let updatedStatuses = allNpoStatuses.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   SetNpoStatuses(updatedStatuses);
  // }, [allNpoStatuses]);

  // useEffect(() => {
  //   let updatedRegions = allNpoRegions.map((el) => {
  //     const { id, name } = el;
  //     return { value: id, label: name };
  //   });
  //   SetNpoRegions(updatedRegions);
  // }, [allNpoRegions]);

  // useEffect(() => {
  //   if (page && resultPerPage && page > 0 && resultPerPage > 0) {
  //     dispatch(
  //       fetchAllNpos({
  //         page: page,
  //         per_page: resultPerPage,
  //         search,
  //         sort: sortedInfo?.order && sortedInfo?.columnKey,
  //         order: sortedInfo?.order
  //           ? sortedInfo?.order === 'ascend'
  //             ? 'asc'
  //             : 'desc'
  //           : null,
  //         show_by_default: true,
  //       })
  //     );
  //   }
  // }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllNpos({
          page: paramsPage,
          per_page: resultPerPage,
          search,
          show_by_default: true,
        })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  // useEffect(() => {
  //   debouncedCallApi();
  // }, [sortedInfo]);

  useEffect(() => {
    if (localStorage.getItem("learningActivitiesManagerState")) {
      const localStorageData = JSON.parse(
        localStorage.getItem("learningActivitiesManagerState")
      );

      if (localStorageData) {
        setTimeout(() => {
          setSearch(localStorageData.search);
          dispatch(setStorage(localStorageData));

          debouncedCallApi();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    let filteredData = allNpos;
    if (search?.name?.length > 0) {
      filteredData = filteredData.filter((el) =>
        el.name?.toLowerCase()?.includes(search.name?.toLowerCase())
      );
    }

    if (search?.stream?.length > 0) {
      filteredData = filteredData.filter((el) =>
        el?.stream?.name?.toLowerCase()?.includes(search.stream?.toLowerCase())
      );
    }

    if (search?.passing_percentage?.length > 0) {
      filteredData = filteredData.filter((el) =>
        el.passing_percentage?.toString()?.includes(search.passing_percentage)
      );
    }

    if (search?.sequence?.length > 0) {
      filteredData = filteredData.filter((el) =>
        el.sequence?.toString()?.includes(search.sequence)
      );
    }

    if ([true, false].includes(search?.final_assessment_required)) {
      filteredData = filteredData.filter(
        (el) =>
          el.final_assessment_required === search.final_assessment_required
      );
    }

    if ([true, false].includes(search?.peer_learning)) {
      filteredData = filteredData.filter(
        (el) => el.peer_learning === search.peer_learning
      );
    }

    setDataSource(filteredData);
  }, [req]);

  useEffect(() => {
    const isSearchChanged =
      JSON.stringify(search) !== JSON.stringify(rest.search);

    if (isSearchChanged) {
      setSearch(rest.search);
    }

    if (isSearchChanged) {
      debouncedCallApi();
    }
  }, []);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 500)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allNpos);
  }, [allNpos]);

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes("all")) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes("not-all")) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };
  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    // if (datePickerOpened) {
    //   return;
    // }
    setSortedInfo(sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteNpo(record));
  };

  const handleSetStorage = (key, value) => {
    dispatch(setStorage({ [key]: value }));
  };

  const handleSearch = (key, value, func = null) => {
    let updatedState = {
      ...search,
      [key]: value,
    };

    setSearch(updatedState);

    if (typeof func === "function") {
      func();
    }

    dispatch(setStorage({ search: updatedState }));
    handleSetStorage("search", updatedState);
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      value={search[key]}
      onChange={(e) => {
        handleSearch(key, e.target.value, debouncedCallApi);
      }}
    />
  );

  const dateInput = (key) => (
    <DatePicker
      placeholder=""
      className="activities-time-picker prod-due-date"
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      suffixIcon={null}
      format="D MMM YYYY"
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
      onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const handleNavigate = (id) => navigate(`/learning-activities/update/${id}`);

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        start_date: "",
        end_date: "",
      });
    } else {
      setSearch({
        ...search,
        start_date: value[0],
        end_date: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const handleDownload = (filename) => {
    let url = `${baseURL}/${filename}`;
    window.open(url, "_blank");
  };

  const columns = [
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">Name</div>
            <div>{searchInput("name")}</div>
          </div>
        );
      },
      dataIndex: "name",
      key: "name",
      sorter: (a, b) =>
        a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === "name" && sortedInfo?.order,
      // sorter: true,
      // sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      // ellipsis: true,
      width: "25%",
    },
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">Sequence</div>
            <div>{searchInput("sequence")}</div>
          </div>
        );
      },
      dataIndex: "sequence",
      key: "sequence",
      sorter: (a, b) => a.sequence - b.sequence,
      sortOrder: sortedInfo?.columnKey === "sequence" && sortedInfo?.order,
      ellipsis: true,
      width: "15%",
      align: "center",
    },
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">Stream</div>
            <div>{searchInput("stream")}</div>
          </div>
        );
      },
      dataIndex: "stream",
      key: "stream",
      sorter: (a, b) =>
        a.stream?.name
          ?.toLowerCase()
          ?.localeCompare(b.stream?.name?.toLowerCase()),
      sortOrder: sortedInfo?.columnKey === "stream" && sortedInfo?.order,
      width: "15%",
      render: (stream) => <div>{stream?.name}</div>,
    },
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">Passing Marks</div>
            <div>{searchInput("passing_percentage")}</div>
          </div>
        );
      },
      dataIndex: "passing_percentage",
      key: "passing_percentage",
      sorter: (a, b) => a.passing_percentage - b.passing_percentage,
      sortOrder:
        sortedInfo?.columnKey === "passing_percentage" && sortedInfo?.order,
      ellipsis: true,
      width: "10%",
      align: "center",
    },
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">
              Final assessment required
            </div>
            <div>
              <Select
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                }}
                size="small"
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className="Select Gender"
                dropdownClassName="select-dropdown-custom"
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "ant-layout-content layout-content site-layout-background custom-textarea"
                  )[0]
                }
                showSearch
                placeholder=""
                value={search["final_assessment_required"]}
                optionFilterProp="children"
                onChange={(e) => {
                  handleSearch(
                    "final_assessment_required",
                    e,
                    debouncedCallApi
                  );
                }}
                optionLabelProp="label"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]?.map(({ label, value }) => {
                  return (
                    <Option value={value} label={label}>
                      {label || ""}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: "final_assessment_required",
      key: "final_assessment_required",
      width: "15%",
      editable: true,
      align: "center",
      sorter: (a, b) =>
        a.final_assessment_required - b.final_assessment_required,
      sortOrder:
        sortedInfo?.columnKey === "final_assessment_required" &&
        sortedInfo?.order,
      render: (final_assessment_required) => {
        let final_assessment = "";
        if (final_assessment_required === true) {
          final_assessment = "Yes";
        } else if (final_assessment_required === false) {
          final_assessment = "No";
        } else final_assessment = "N/A";
        return <div style={{ paddingLeft: "12px" }}>{final_assessment}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align="top" style={{ width: "100%" }}>
            <div className="special-table-header-text">
              Peer Learning Required
            </div>
            <div>
              <Select
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                }}
                size="small"
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className="Select Gender"
                dropdownClassName="select-dropdown-custom"
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "ant-layout-content layout-content site-layout-background custom-textarea"
                  )[0]
                }
                showSearch
                placeholder=""
                value={search["peer_learning"]}
                optionFilterProp="children"
                onChange={(e) => {
                  handleSearch("peer_learning", e, debouncedCallApi);
                }}
                optionLabelProp="label"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]?.map(({ label, value }) => {
                  return (
                    <Option value={value} label={label}>
                      {label || ""}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: "peer_learning",
      key: "peer_learning",
      width: "15%",
      editable: true,
      align: "center",
      sorter: (a, b) => a.peer_learning - b.peer_learning,
      sortOrder: sortedInfo?.columnKey === "peer_learning" && sortedInfo?.order,
      render: (peer_learning) => {
        let peerLearningText = "";
        if (peer_learning === true) {
          peerLearningText = "Yes";
        } else if (peer_learning === false) {
          peerLearningText = "No";
        } else {
          peerLearningText = "N/A";
        }
        return <div style={{ paddingLeft: "12px" }}>{peerLearningText}</div>;
      },
    },
    {
      title: null,
      dataIndex: "",
      key: "action",
      width: "5%",
      align: "center",
      render: (_, record) => {
        // if (!canUpdate && !canDelete) {
        //   return;
        // }
        return deleteNpoLoading && record._id === currentDelRecord._id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align="baseline">
            {/* {canUpdate && ( */}
            <EditIcon
              width={18}
              height={18}
              className="custom-icon"
              onClick={() => {
                handleNavigate(record._id);
                dispatch(setNpoDetailsIsEdit(true));
              }}
            />
            {/* // )} */}
            {/* {canDelete && (
              <Popconfirm
                title='Are you sure you want to delete this NPO?'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteIcon width={18} height={18} className='custom-icon' />
              </Popconfirm>
            )} */}
          </Space>
        );
      },
    },
  ];

  return (
    <div className="page-top-div">
      <span className="text-span">Learning Activities</span>
      <Row className="production-manager-parent-row">
        <Col span={24} className="heading-col">
          <Row align="middle" gutter={[50, 0]} justify="space-between">
            <Col>
              <Row align="middle" gutter={[20, 0]}>
                <Col>
                  <div>
                    <span className="heading-span">
                      Learning Activities Register
                    </span>
                  </div>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      navigate("/learning-activities/create");
                      dispatch(setNpoDetailsIsEdit(true));
                    }}
                    icon={<PlusOutlined />}
                    className="add-user-btn"
                    size="middle"
                    style={{
                      width: "105%",
                    }}
                  >
                    Learning Activity
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      handleSetStorage("search", {});
                      setSearch({});
                      debouncedCallApi();
                    }}
                    icon={<CloseOutlined />}
                    className="add-user-btn"
                    size="middle"
                  >
                    Clear Filters
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table
            className="special-table"
            columns={columns}
            scroll={{ x: !fetchNposLoading }}
            onChange={handleChange}
            dataSource={dataSource}
            pagination={false}
            sortUpIcon={<UpOutlined />}
            sortDownIcon={<DownOutlined />}
            loading={fetchNposLoading}
            onRow={(record) => {
              return {
                onClick: () => handleNavigate(record._id),
              };
            }}
          />
          {/* <CustomPagination
        page={page}
        setPage={setPage}
        resultPerPage={resultPerPage}
        setResultPerPage={setResultPerPage}
        debouncedCallApi={debouncedCallApi}
        totalRecord={totalRecords}
      /> */}
        </Col>
      </Row>
    </div>
  );
};

export default BooksTable;
