import React from 'react';
import { Result } from 'antd';
// import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
  //   const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Result
        status='success'
        title='Thank You for Accepting the Invitation!'
        subTitle='We appreciate your confirmation. Looking forward to collaborating with you.'
        // extra={[
        //   <Button
        //     type='primary'
        //     key='dashboard'
        //     onClick={() => navigate('/dashboard')}
        //   >
        //     Go to Dashboard
        //   </Button>,
        // ]}
      />
    </div>
  );
};

export default ThankYou;
