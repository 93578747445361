import { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import { message as antdAlert } from 'antd';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';

const UseResetPassword = (Form) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { token } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  // Extract the tenant parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const tenant = queryParams.get('tenant');

  const handlePassword = async ({ password_confirmation, password }) => {
    setLoading(true);

    const url = `${baseURL}/api/v2/auth/reset-password`;
    const options = {
      token,
      password,
    };

    const headers = { tenant };

    try {
      const response = await axios.post(url, options, { headers });

      const { success, message } = response.data;

      if (success) {
        antdAlert.success(message);
        setLoading(false);
        form?.resetFields();
        navigate('/reset-password-success');
      } else {
        antdAlert.error(message);
        setLoading(false);
      }
    } catch (error) {
      antdAlert.error(
        error?.response?.data?.message || 'Something went wrong!'
      );
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    console.log('values', values);
    handlePassword(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return [onFinish, onFinishFailed, loading, form];
};

export default UseResetPassword;
