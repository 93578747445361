import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import * as constants from '../constants';
import { baseURL } from '../../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../../components/unauthorizeHandler';
import { updateNpoSuccess } from '../../../redux/actions';

export function* handleFetchSiteStatuses({ data }) {
  try {
    const response = yield axios.post(
      `${baseURL}/api/v2/admin/learning-activities/stickers`,
      {
        learning_activity_id: data,
      }
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchSiteStatusesSuccess(response.data.data.stickers));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchSiteStatusesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateSiteStatuses({ data }) {
  // const { _id, learning_activity_id, name, start_range, end_range } =
  //   data;

  const { onUpdate, ...rest } = data;

  try {
    // const fd = new FormData();
    // fd.append('name', name);
    // fd.append('learning_activity_id', learning_activity_id);
    // fd.append('_id', _id);
    // fd.append('start_range', start_range);
    // fd.append('end_range', end_range);

    // if (rest?.icon) {
    //   fd.append('icon', rest.icon);
    // }

    const response = yield axios.put(
      `${baseURL}/api/v2/admin/learning-activities/update-sticker`,
      { ...rest }
    );

    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateSiteStatusesSuccess(response.data.data));
      onUpdate();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateSiteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSiteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteSiteStatuses({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v2/admin/learning-activities/stickers/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteSiteStatusesSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteSiteStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteSiteStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateSiteStatuses({ data }) {
  const { name, icon, learning_activity_id } = data;
  try {
    const fd = new FormData();
    fd.append('name', name);
    fd.append('icon', icon);
    fd.append('learning_activity_id', learning_activity_id);

    const response = yield axios.post(
      `${baseURL}/api/v2/admin/learning-activities/add-sticker`,
      fd
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createSiteStatusesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createSiteStatusesError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
    yield put(actions.createSiteStatusesError());
  }
}

export function* handleUpdateSiteOrder({ data }) {
  // const fd = new FormData();
  // data.map((el, i) => fd.append(`statuses[${el.id}]`, i + 1));

  try {
    const response = yield axios.put(
      `${baseURL}/api/v2/admin/learning-activities/sort-sticker`,
      data
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      const { learningActivity, stickers } = response.data.data;
      // yield put(updateNpoSuccess({ ...learningActivity, stickers }));

      yield put(actions.updateSiteOrderSuccess(stickers));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateSiteOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateSiteOrderError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* watcherLearningActivityStickersSaga() {
  yield takeEvery(constants.FETCH_SITE_STATUSES, handleFetchSiteStatuses);
  yield takeEvery(constants.CREATE_SITE_STATUSES, handleCreateSiteStatuses);
  yield takeEvery(constants.UPDATE_SITE_STATUSES, handleUpdateSiteStatuses);
  yield takeEvery(constants.UPDATE_ORDER_SITE_STATUS, handleUpdateSiteOrder);
}
